<template lang="">
  <div>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <template v-if="isLoaded">
      <div
        class="grid lg:grid-cols-2 lg:space-x-2 md:space-x-0 md:space-y-2 md:grid-cols-1"
      >
        <info-grid
          grid-class="grid-cols-1"
          title="Marketplace Info"
          :data="getProfile()"
        />
        <info-grid
          grid-class="grid-cols-1"
          title="Fleet Info"
          :data="getFleetData()"
        />
      </div>
    </template>
  </div>
</template>
<script>
import InfoGrid from '@/components/layout/InfoGrid'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { getFormattedDateTime } from '@/utils/datetime'
import { useEndpoints } from '@/composables'
export default {
  name: 'ViewMarketplaceProfile',
  components: {
    InfoGrid,
  },
  data() {
    return {
      isLoaded: false,
      profileData: {},
      fallbackText: '--',
      lockManufacturers: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {
    await this.$http.get(MarketplaceConfig.api.details(this.id)).then((res) => {
      this.isLoaded = true
      this.profileData = res.data.data
    })

    await this.$http
      .get(useEndpoints.manufacturers.index())
      .then((res) => {
        this.lockManufacturers = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          }
        })
      })
      .catch((e) => console.log(e))
  },
  methods: {
    getFormattedDateTime,
    getLockManufactureName(id) {
      const selectedManufacture = this.lockManufacturers.find(
        (item) => item.id === id
      )
      return selectedManufacture.name
    },
    getProfile() {
      let profile = []
      profile.push({
        text: 'Marketplace Name',
        value: this.profileData.brand_name,
      })
      profile.push({
        text: 'Business Model',
        value:
          this.profileData.business_model === 'SH' ? 'Sharing' : 'Store Rental',
      })
      profile.push({
        text: 'Target Group',
        value: this.profileData.target_group,
      })
      profile.push({
        text: 'Employee Size',
        value: this.profileData.company_employee_size,
      })

      profile.push({
        text: 'Lock Manufacturer Name',
        value: this.getLockManufactureName(this.profileData.lock_manufacturer),
      })
      profile.push({
        text: 'Unit Vehicle Price',
        value: `${this.profileData.fleet.country.currency_symbol} ${this.profileData.unit_vehicle_price}`,
      })
      profile.push({
        text: 'Number of vehicles to deploy',
        value: this.profileData.number_of_vehicles_to_deploy,
      })
      profile.push({
        text: 'Number of vehicles sold',
        value: this.profileData.number_of_vehicles_sold,
      })
      profile.push({
        text: 'Founded date',
        value: this.getFormattedDateTime(
          this.profileData.year_founded,
          'D MMM, YYYY'
        ),
      })
      profile.push({
        text: 'Order start date',
        value: this.getFormattedDateTime(
          this.profileData.order_start_date,
          'D MMM, YYYY'
        ),
      })
      profile.push({
        text: 'Order End date',
        value: this.getFormattedDateTime(
          this.profileData.order_end_date,
          'D MMM, YYYY'
        ),
      })
      profile.push({
        text: 'Operation start date',
        value: this.getFormattedDateTime(
          this.profileData.operation_start_date,
          'D MMM, YYYY'
        ),
      })
      profile.push({
        text: 'Activation date',
        value: this.getFormattedDateTime(
          this.profileData.activation_date,
          'D MMM, YYYY'
        ),
      })
      profile.push({
        text: 'Rent share 1st year %',
        value: this.profileData.rent_share_1st_year_by_percentage,
      })
      profile.push({
        text: 'Rent share 2nd year %',
        value: this.profileData.rent_share_2nd_year_by_percentage,
      })
      profile.push({
        text: 'Rent share 3rd year %',
        value: this.profileData.rent_share_3rd_year_by_percentage,
      })
      profile.push({
        text: 'Rent share 4th year %',
        value: this.profileData.rent_share_4th_year_by_percentage,
      })
      profile.push({
        text: 'Tax in Percentage',
        value: this.profileData.tax_in_percentage,
      })
      profile.push({
        text: 'Website URL',
        value: this.profileData.website_url || this.fallbackText,
      })
      profile.push({
        text: 'Facebook URL',
        value: this.profileData.facebook_url || this.fallbackText,
      })
      profile.push({
        text: 'LinkedIn URL',
        value: this.profileData.linkedin_url || this.fallbackText,
      })
      profile.push({
        text: 'Instagram URL',
        value: this.profileData.instagram_url || this.fallbackText,
      })
      profile.push({
        text: 'Youtube URL',
        value: this.profileData.youtube_url || this.fallbackText,
      })
      profile.push({
        text: 'Playstore URL',
        value: this.profileData.android_play_store_url || this.fallbackText,
      })
      profile.push({
        text: 'Appstore URL',
        value: this.profileData.ios_app_store_url || this.fallbackText,
      })
      return profile
    },
    getFleetData() {
      let fleet = []
      fleet.push({
        text: 'Fleet Name',
        value: this.profileData.fleet.name,
      })
      fleet.push({
        text: 'Fleet ID',
        value: this.profileData.fleet.id,
      })
      fleet.push({
        text: 'Fleet Organization',
        value: this.profileData.fleet.organization.name || this.fallbackText,
      })
      fleet.push({
        text: 'Fleet Location',
        value: this.profileData.fleet.warehouse_name || this.fallbackText,
      })
      fleet.push({
        text: 'Fleet Country',
        value: this.profileData.fleet.country.name || this.fallbackText,
      })
      fleet.push({
        text: 'Billing Plan',
        value: this.profileData.fleet.billing_plan || this.fallbackText,
      })
      fleet.push({
        text: 'Alert Email Address',
        value: this.profileData.fleet.email_address || this.fallbackText,
      })
      fleet.push({
        text: 'Operational Vehicle',
        value: this.profileData.fleet.operational_vehicle || this.fallbackText,
      })
      fleet.push({
        text: 'Created At',
        value: this.getFormattedDateTime(
          this.profileData.fleet.created_at,
          'D MMM, YYYY'
        ),
      })
      fleet.push({
        text: 'Fleet Visibility',
        value: this.profileData.fleet.fleet_visibility || this.fallbackText,
      })
      fleet.push({
        text: 'Domain',
        value: this.profileData.fleet.domain || this.fallbackText,
      })
      fleet.push({
        text: 'Port',
        value: this.profileData.fleet.port || this.fallbackText,
      })
      return fleet
    },
  },
}
</script>
<style lang=""></style>
